import * as React from "react"
import { Link } from 'gatsby'
import Header from "../components/header"
import Footer from "../components/footer"
import Wrapper from "../components/wrapper"
import blur from "../images/blur.svg"

const IndexPage = () => {
  const paragraphStyles = 'text-sm font-normal text-gray-800 mb-6'
  const linkStyles = "text-black no-underline px-1.5 py-1 -mx-1.5 rounded hover:bg-black/5 transition ease-in-out duration-200"
  return (
    <Wrapper path="/">
      <div className="p-8 sm:p-12 md:p-24 text-gray-800">
        <div className="m-0 mb-24" style={{ maxWidth: 680 }}>
          <h1 className="text-sm font-semibold mb-16">Hey, I'm Dylan 👋</h1>
          <p className={paragraphStyles}>Designer, developer, and serial starter of projects.</p>
          {/* <p className={paragraphStyles}>I work as a product designer at <a href="https://convertkit.com" target="_blank" className={linkStyles}>ConvertKit</a> where I focus on improving the UX of our editors and evolving the design system from pixels to code.</p>
          <p className={paragraphStyles}>On the side, I’m building <a href="https://usestellar.com" target="_blank" className={linkStyles}>Stellar,</a> a changelog for SaaS companies. I’ve also made a few <a href="https://figma.com/dylanfeltus" className={linkStyles}>Figma plugins</a> with more than 1mm active installs.</p> */}
          <div className="flex items-center">
            <span className="mr-6">Connect</span>
            <a href="https://twitter.com/dylanfeltus" className="mr-6" target="_blank">Twitter</a>
            <a href="https://instagram.com/dylanfeltus" className="mr-6" target="_blank">Instagram</a>
            <a href="https://www.linkedin.com/in/dylan-feltus-6b968754" className="mr-6" target="_blank">LinkedIn</a>
          </div>
        </div>
      </div>
      <div className="hidden lg:block fixed top-0 right-0 bottom-0" style={{ width: 420, height: "100vh", background: "#FFFAF2"}}></div>
    </Wrapper>
  )
}

export default IndexPage
